<template>
  <v-container grid-list-lg fluid>
    <v-row row wrap>
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col class="pt-0" cols="12" sm="12">
            <v-card outlined>
              <v-toolbar dense flat color="grey lighten-3">
                <v-toolbar-title>Sales Rep</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <SaleRep
                  @change="updateSaleman"
                  v-model="selectedUser"
                  :initValue="getCustomer.SalesRep"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="pt-0" cols="12" sm="12">
            <v-card outlined>
              <v-toolbar dense flat color="grey lighten-3">
                <v-toolbar-title> Note </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="checkRightStatus(36)"
                  small
                  color="info"
                  dark
                  class="mr-2"
                  @click="updateNote()"
                  fab
                  text
                >
                  <v-icon>save</v-icon>
                </v-btn>
                <div v-if="checkRightStatus(37)">
                  <span class="mr-2">Alert</span>
                  <toggle-button
                    @change="updateAlert"
                    :labels="true"
                    v-if="getCustomer"
                    color="red"
                    v-model="getCustomer.alert"
                    :value="getCustomer.alert"
                    :sync="true"
                  ></toggle-button>
                </div>
              </v-toolbar>
              <v-card-text>
                <v-textarea
                  v-model="note"
                  outlined
                  hide-details
                  label="Customer Note"
                ></v-textarea>

                <!-- <div v-if="getCustomer && getCustomer.note">{{ getCustomer.note }}</div> -->
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" sm="12">
            <PaymentPie :CustomerId="getCustomer.id"/>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <Comment :RelationId="getCustomer" :TypeId="2" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import customerService from "../service";
const Comment = () => import("@/modules/Comment/components/List");
import SaleRep from "@/modules/User/components/AutoComplete.vue";
// import PaymentPie from "@/modules/Customer/Payment/Chart/PaymentMethodPie";

export default {
  name: "customer-dashbaord",
  data() {
    return {
      loading: false,
      note: null,
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters("customer", ["getCustomer"]),
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  components: {
    Comment,
    SaleRep,
  },
  watch: {
    getCustomer: {
      immediate: true,
      handler(val) {
        this.note = val.note;
      },
    },
  },
  methods: {
    ...mapActions("customer", ["setCustomer"]),
    updateNote() {
      let data = {
        ...this.getCustomer,
        note: this.note,
      };
      this.updateCustomer(data);
    },
    updateAlert(val) {
      let data = {
        ...this.getCustomer,
        alert: val.value,
      };
      this.updateCustomer(data);
    },
    updateSaleman(val) {
      let data = {
        ...this.getCustomer,
        SalesRepUserId: val.id,
      };
      this.updateCustomer(data);
    },
    updateCustomer(data) {
      return customerService
        .update(this.getCustomer.id, data)
        .then(() => {
          // console.log('result', result)
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "success",
              title: "Update Successfully",
            });
          this.setCustomer(this.getCustomer.id);
        });
    },
  },
};
</script>
